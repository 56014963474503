import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes, useNavigate, HashRouter } from "react-router-dom";
import "./scss/style.scss";
import { useMsal } from "@azure/msal-react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { msalLogIn } from "./misalInstance";
import { useIsAuthenticated } from "@azure/msal-react";
import { useData } from "./DataProvider";
initializeIcons();

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page401 = React.lazy(() => import("./views/pages/page401/Page401"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const IsLogin = () => {
  const { docuklaudApi } = useData();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  async function init() {
    if (!isAuthenticated) {
      try {
        const resp = await msalLogIn();
      } catch (error) {
        navigate("/login");
      }
    } else {
      navigate("/dashboard");
    }
  }
  React.useEffect(() => {
    init();
  }, [isAuthenticated]);

  return isAuthenticated ? <DefaultLayout /> : <></>;
};

const App = () => {
  React.useEffect(() => {}, []);
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/**@ts-ignore */}
          <Route exact path="/login" name="Login Page" element={<Login />} />
          {/**@ts-ignore */}
          <Route exact path="/401" name="Page 401" element={<Page401 />} />
          {/**@ts-ignore */}
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          {/**@ts-ignore */}
          <Route path="*" name="Home" element={<IsLogin />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
