import { PublicClientApplication } from "@azure/msal-browser";
let redirectUri = `${window.location.origin}/callback`;
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  redirectUri = "http://" + window.location.host;
}
const msalConfig = {
  auth: {
    clientId: "88c37ee4-e24f-486e-9a61-98651c7b5722", //*"ac8aa586-4534-49f5-bbac-087053d0cf50",
    authority:
      "https://login.microsoftonline.com/3840d0fb-f5ab-403e-97bf-d9f4f20266b3",
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "localStorage", //keeps login token across sites
    storeAuthStateInCookie: true, //stores the auth state in your cache
  },
};

const msalInstance =
  //@ts-ignore
  await PublicClientApplication.createPublicClientApplication(msalConfig);

function initializeMsal() {
  return msalInstance
    .loginRedirect({
      authority: "api://docuklaud-backend/access_as_user",
      scopes: [
        "api://a474d301-e75c-40c3-b796-52d47c631049/access_as_user",
        "user.read",
      ],
    })
    .then((response) => {
      console.log("MSAL initialized successfully");
    })
    .catch((error) => {
      console.error("Error initializing MSAL:", error);
    });
}

async function msalLogIn() {
  const loginRequest = {
    authority:
      "https://login.microsoftonline.com/3840d0fb-f5ab-403e-97bf-d9f4f20266b3",
    scopes: ["api://docuklaud-backend/access_as_user", "user.read"],
    accessTokenAcceptedVersion: 2,
  };
  return msalInstance.loginPopup(loginRequest);
}

export { msalInstance, initializeMsal, msalLogIn };
